<bit-container>
  <p>{{ "passwordsReportDesc" | i18n }}</p>
  <div *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div class="tw-mt-4" *ngIf="!loading">
    <bit-table-scroll [dataSource]="dataSource" [rowSize]="53">
      <ng-container header>
        <th bitCell bitSortable="hostURI">{{ "application" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "weakness" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "timesReused" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "timesExposed" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "totalMembers" | i18n }}</th>
      </ng-container>
      <ng-template bitRowDef let-row>
        <td bitCell>
          <ng-container>
            <span>{{ row.hostURI }}</span>
          </ng-container>
        </td>
        <td bitCell class="tw-text-right">
          <span
            bitBadge
            *ngIf="passwordStrengthMap.has(row.id)"
            [variant]="passwordStrengthMap.get(row.id)[1]"
          >
            {{ passwordStrengthMap.get(row.id)[0] | i18n }}
          </span>
        </td>
        <td bitCell class="tw-text-right">
          <span bitBadge *ngIf="passwordUseMap.has(row.login.password)" variant="warning">
            {{ "reusedXTimes" | i18n: passwordUseMap.get(row.login.password) }}
          </span>
        </td>
        <td bitCell class="tw-text-right">
          <span bitBadge *ngIf="exposedPasswordMap.has(row.id)" variant="warning">
            {{ "exposedXTimes" | i18n: exposedPasswordMap.get(row.id) }}
          </span>
        </td>
        <td bitCell class="tw-text-right" data-testid="total-membership">
          {{ totalMembersMap.get(row.id) || 0 }}
        </td>
      </ng-template>
    </bit-table-scroll>
  </div>
</bit-container>
