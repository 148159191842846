<ng-container *ngIf="filters && filters.length">
  <div *ngIf="headerInfo.showHeader" class="filter-heading">
    <button
      type="button"
      class="toggle-button"
      (click)="toggleCollapse(headerNode.node)"
      [attr.aria-expanded]="!isCollapsed(headerNode.node)"
      appA11yTitle="{{ 'toggleCollapse' | i18n }}: {{ headerNode.node.name | i18n }}"
      aria-controls="sub-filters"
    >
      <i
        class="bwi bwi-fw"
        aria-hidden="true"
        [ngClass]="isCollapsed(headerNode.node) ? 'bwi-angle-right' : 'bwi-angle-down'"
      ></i>
    </button>
    <button
      type="button"
      *ngIf="headerInfo.isSelectable"
      appA11yTitle="{{ (isOrganizationFilter ? 'vault' : 'filter') | i18n }}: {{
        headerNode.node.name | i18n
      }}"
      class="filter-button"
      (click)="onFilterSelect(headerNode)"
    >
      <h3
        [ngClass]="{
          active: isAllVaultsSelected || isNodeSelected(headerNode),
        }"
      >
        &nbsp;{{ headerNode.node.name | i18n }}
      </h3>
    </button>
    <h3 *ngIf="!headerInfo.isSelectable" class="filter-title">
      &nbsp;{{ headerNode.node.name | i18n }}
    </h3>
  </div>
  <ul
    id="{{ headerNode.node.name }}-filters"
    *ngIf="!isCollapsed(headerNode.node)"
    class="filter-options"
  >
    <ng-template #recursiveFilters let-filters>
      <li
        *ngFor="let f of filters"
        [ngClass]="{
          active: isNodeSelected(f),
        }"
        class="filter-option"
      >
        <span class="filter-buttons">
          <button
            type="button"
            *ngIf="f.children.length"
            appA11yTitle="{{ 'toggleCollapse' | i18n }}: {{ f.node.name }}"
            (click)="toggleCollapse(f.node)"
            [attr.aria-expanded]="!isCollapsed(f.node)"
            [attr.aria-controls]="f.node.name + '_children'"
            class="toggle-button"
          >
            <i
              class="bwi bwi-fw"
              [ngClass]="{
                'bwi-angle-right': isCollapsed(f.node),
                'bwi-angle-down': !isCollapsed(f.node),
              }"
              aria-hidden="true"
            ></i>
          </button>
          <button
            type="button"
            class="filter-button"
            appA11yTitle="{{ isOrganizationFilter ? 'vault' : ('filter' | i18n) }}: {{
              f.node.name
            }}"
            [ngClass]="{ 'disabled-organization': isOrganizationFilter && !f.node.enabled }"
            (click)="onFilterSelect(f)"
          >
            <i
              *ngIf="f.children.length === 0"
              class="bwi bwi-fw {{ f.node.icon }}"
              aria-hidden="true"
            ></i>
            &nbsp;{{ f.node.name }}
          </button>
          <span class="ml-auto tw-flex tw-items-center">
            <button
              type="button"
              *ngIf="editInfo && f.node.id"
              class="edit-button"
              (click)="onEdit(f)"
              appA11yTitle="{{ editInfo.text | i18n }}"
            >
              <i class="bwi bwi-pencil bwi-fw" aria-hidden="true"></i>
            </button>
            <i
              *ngIf="isOrganizationFilter && !f.node.enabled"
              class="org-options bwi bwi-fw bwi-exclamation-triangle text-danger"
              [attr.aria-label]="'organizationIsDisabled' | i18n"
              appA11yTitle="{{ 'organizationIsDisabled' | i18n }}"
            ></i>
            <ng-container *ngIf="optionsInfo && !f.node.hideOptions">
              <ng-container
                *ngComponentOutlet="optionsInfo.component; injector: createInjector(f.node)"
              ></ng-container>
            </ng-container>
          </span>
        </span>
        <ul
          [id]="f.node.name + '_children'"
          class="nested-filter-options"
          *ngIf="f.children.length && !isCollapsed(f.node)"
        >
          <ng-container *ngTemplateOutlet="recursiveFilters; context: { $implicit: f.children }">
          </ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="recursiveFilters; context: { $implicit: filters }"
    ></ng-container>
    <li class="filter-option" *ngIf="showAddLink">
      <span class="filter-buttons">
        <a href="#" routerLink="{{ addInfo.route }}" class="filter-button tw-truncate">
          <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
          &nbsp;{{ addInfo.text | i18n }}
        </a>
      </span>
    </li>
  </ul>
  <hr *ngIf="divider" />
</ng-container>
