<bit-container>
  <p>{{ "passwordsReportDesc" | i18n }}</p>
  <div *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div class="tw-mt-4" *ngIf="!loading && dataSource.data.length">
    <bit-table-scroll [dataSource]="dataSource" [rowSize]="53">
      <ng-container header>
        <th bitCell></th>
        <th bitCell bitSortable="name">{{ "name" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "weakness" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "timesReused" | i18n }}</th>
        <th bitCell class="tw-text-right">{{ "timesExposed" | i18n }}</th>
      </ng-container>
      <ng-template bitRowDef let-row>
        <td bitCell>
          <app-vault-icon [cipher]="row"></app-vault-icon>
        </td>
        <td bitCell>
          <ng-container>
            <span>{{ row.name }}</span>
          </ng-container>
          <br />
          <small>{{ row.subTitle }}</small>
        </td>
        <td bitCell class="tw-text-right">
          <span
            bitBadge
            *ngIf="row.weakPasswordDetail"
            [variant]="row.weakPasswordDetail?.detailValue.badgeVariant"
          >
            {{ row.weakPasswordDetail?.detailValue.label | i18n }}
          </span>
        </td>
        <td bitCell class="tw-text-right">
          <span bitBadge *ngIf="passwordUseMap.has(row.login.password)" variant="warning">
            {{ "reusedXTimes" | i18n: passwordUseMap.get(row.login.password) }}
          </span>
        </td>
        <td bitCell class="tw-text-right">
          <span bitBadge variant="warning" *ngIf="row.exposedPasswordDetail">
            {{ "exposedXTimes" | i18n: row.exposedPasswordDetail?.exposedXTimes }}
          </span>
        </td>
      </ng-template>
    </bit-table-scroll>
  </div>
</bit-container>
