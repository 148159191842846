<ng-container>
  <bit-table-scroll
    [dataSource]="dataSource"
    [rowSize]="53"
    class="tw-table tw-w-full table-hover table-list"
  >
    <ng-container header>
      <th *ngIf="isCriticalAppsFeatureEnabled"></th>
      <th bitSortable="applicationName" bitCell>{{ "application" | i18n }}</th>
      <th bitSortable="atRiskPasswordCount" bitCell>{{ "atRiskPasswords" | i18n }}</th>
      <th bitSortable="passwordCount" bitCell>{{ "totalPasswords" | i18n }}</th>
      <th bitSortable="atRiskMemberCount" bitCell>{{ "atRiskMembers" | i18n }}</th>
      <th bitSortable="memberCount" bitCell>{{ "totalMembers" | i18n }}</th>
    </ng-container>
    <ng-template bitRowDef let-row>
      <td
        bitCell
        *ngIf="isCriticalAppsFeatureEnabled && showRowCheckBox"
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        <input
          bitCheckbox
          type="checkbox"
          *ngIf="!row.isMarkedAsCritical"
          [checked]="selectedUrls.has(row.applicationName)"
          (change)="checkboxChange(row.applicationName, $event)"
        />
        <i class="bwi bwi-star-f" *ngIf="row.isMarkedAsCritical"></i>
      </td>
      <td
        bitCell
        *ngIf="!showRowCheckBox"
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        <i class="bwi bwi-star-f" *ngIf="row.isMarkedAsCritical"></i>
      </td>
      <td
        class="tw-cursor-pointer"
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
        (click)="showAppAtRiskMembers(row.applicationName)"
        (keypress)="showAppAtRiskMembers(row.applicationName)"
        bitCell
      >
        <span>{{ row.applicationName }}</span>
      </td>
      <td
        bitCell
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        <span>
          {{ row.atRiskPasswordCount }}
        </span>
      </td>
      <td
        bitCell
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        <span>
          {{ row.passwordCount }}
        </span>
      </td>
      <td
        bitCell
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        <span>
          {{ row.atRiskMemberCount }}
        </span>
      </td>
      <td
        bitCell
        data-testid="total-membership"
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        {{ row.memberCount }}
      </td>
      <td
        bitCell
        *ngIf="showRowMenuForCriticalApps"
        [ngClass]="{ 'tw-bg-primary-100': isDrawerIsOpenForThisRecord(row.applicationName) }"
      >
        <button
          [bitMenuTriggerFor]="rowMenu"
          type="button"
          bitIconButton="bwi-ellipsis-v"
          size="small"
          appA11yTitle="{{ 'options' | i18n }}"
        ></button>

        <bit-menu #rowMenu>
          <button type="button" bitMenuItem (click)="unmarkAsCriticalApp(row.applicationName)">
            <i aria-hidden="true" class="bwi bwi-star-f"></i> {{ "unmarkAsCriticalApp" | i18n }}
          </button>
        </bit-menu>
      </td>
    </ng-template>
  </bit-table-scroll>
</ng-container>
